<template>
  <div>
    hello world!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!11
  </div>
</template>

<script>
  export default {
    components: {
    },

    data() {
      return {
        bg,
        text: '研小白 - 考研数字化备考服务平台',
        device: "",
        imgD: "",
        id: "",
        browser: {},
        downloadUrl:"",
      }
    },
    created() {
      
    },
    mounted() {
    

    },
    methods: {
      
    }
  }
</script>
